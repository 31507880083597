.underline {
  /* border: 0.05rem solid #8B939C; */
  border:none;
  height: 20px;
 	width: 100%;
	height: 50px;
	margin-top: 0;
	border-bottom: 1px solid #1f1209;
	box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px; 
  margin-bottom: 1rem;
}