@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

.coursey {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color:rgb(230, 230, 230);
}

.top-container {
    padding: 2rem;
    padding-top: 6rem;
    z-index: 2;
    background-color: #fcba03;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.content-container {
    max-width: 60rem;
    margin: 0 auto;
}

.course-layout {
    z-index: 1;
    width: 80%;
    min-height: 50rem;
    background-color: #dbdbdb;
    box-shadow: 12px 0 30px -4px rgba(95, 95, 95, 0.7), -12px 0 30px -4px rgba(95, 95, 95, 0.7);
}

.top-flex{
    display: flex;
    justify-content: space-between;
}

.title-flex{
    border-bottom: 1px solid black;
    display: inline-flex;
}

.course-id{
    font-family: 'Roboto';
    font-size: 2.2rem;
    padding-right: 1.5rem;
    font-weight: 400;
}

.course-name {
    font-family: 'Roboto';
    font-size: 2.2rem;
    font-weight: 300;
}

.school-name {
    margin-top: 0.4rem;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: lighter;
}

.review-container {
    margin-top: 2rem;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
}

.review-text {
    margin-right: 0.5rem;
    line-height: 1.7rem;
}

.review-flex {
    display: inline-flex;
}

.review-container > br {
    display: none;
}

.fraction-container {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: rgb(61, 61, 61);
    border-radius: 0.3rem;
}

.review-actual-value {
    padding-bottom: 0.5rem;
    font-weight: bolder;
    font-size:1.2rem;
}

.review-max-value {
    padding-top: 0.5rem;
    color: white;
}

.roboto {
    font-family: 'Roboto';
    font-weight: 300;
}

#no-comment-span {
    text-align: center;
    width: 100%;
}

.button-flex {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}

.add-button {
    margin-right: 1.2rem;
    margin-left: 1.2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    background-color: #4891FF;
    color: white;
    border-radius: 0.4rem;
    transition-duration: 0.4s;
}

.add-button:hover {
    /* transition-duration: 0.4s; */
    background-color: white;
    color: black;
}

#no-comment{
    color: #616161;
    width: 100%;
}

@media (max-width:900px) {
    .course-layout {
        width: 100%;
    }

    .review-container {
        display: block;
    }

    .review-container > br{
        display: block;
    }

    .course-name {
        font-size: 1.5rem;
    }

    .course-id{
        font-size: 1.5rem;
        padding-right: 1.0rem;
    }

    .school-name {
        font-size: 1rem;
    }
}