@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#title-with-image {
  background-size: cover;
  background-image: url('/chairs.jpg');
  height: 30rem;
}
  
#title-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, 60%);
}
  
.main-title-1 {
  color: #fcba03;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9rem;
  font-weight:bolder;
  text-shadow: 5px 5px 10px #2b2b2b;
  animation: appear2 1s;
}

.main-title-2 {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9rem;
  font-weight:bolder;
  text-shadow: 5px 5px 10px #2b2b2b;
  animation: appear 1s;
}

.sub-title-text {
    color: white;
    width: 100%;
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
    transform: translate(0%, 300%);
    text-shadow: 3px 3px 5px #2b2b2b;
}

.desc-container-container {
  width: 100%;
  background-color: rgb(238, 238, 238);
}

.description-flex {
  min-height: 11rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top:2rem;
  margin-bottom:2rem;
}

.simple-flex {
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 3rem;
}

.description-1 {
  color: black;
  margin: 0;
  max-width: 30rem;
  margin-right: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 2rem;
}

.highlight {
  color: #fcba03;
}

.description-2 {
  color: black;
  margin: 0;
  max-width: 30rem;
  padding-top: 1rem;
  margin-right: 3rem;
  margin-left: 3rem;
  text-align: left;
}

.database-container {
  margin-right: 2rem;
}

.helper-container {
  margin: 2rem;
  padding: 2rem;
  background-color:white;
  box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.4);
  font-family: 'Open Sans', sans-serif;
  border-radius: 2rem;
  font-weight: bold;
}

.use-background {
    background-size: cover;
    background-image: url('/cu_campus.jpg');
    min-height: 20rem;
}

.use-container {
    background-color: rgba(252, 186, 3, 0.7); 
    height:100%;
    padding-top: 2rem;
}

.use-container p {
    color: black;
    margin: 0;
    text-align: center;
    padding-top: 1rem;
}

.use-grid {
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    height: 15rem;
    max-width: 1200px;
    margin: 0 auto;
}

.use-section {
    margin: 0 auto;
    display: block;
}

.contribute {
  color: white;
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.3rem;
  text-shadow: 3px 3px 6px #2b2b2b;
  margin-bottom:1rem;
}

.icon-credit {
  color: gray;
  font-size: 0.7rem;
}

@media (max-width:900px) {

  #title-with-image {
      height: 15rem;
  }

  #title-flex {
      transform: translate(0%, 150%);
  }    

  .about-container{
      display: block;
  }

  .overlap{
      display: none;
  }

  .sub-title-text {
      font-size: 0.75rem;
      transform: translate(0%, 650%);
  }

  .main-title-1 {
      font-size: 3rem;
  }

  .main-title-2 {
    font-size: 3rem;
  }

  .use-grid {
      display: block;
  }

  .use-background {
    min-height: 35rem;
  }

  .use-container {
    min-height: 35rem;
  }
    
  .icon-credit {
    display: none;
  }

  .database-container {
    display: none;
  }
}

@keyframes appear {
    0% {
      color: transparent;
      text-shadow: 5px 5px 10px transparent;
    }
    100% {
      color: white;
      text-shadow: 5px 5px 10px #2b2b2b;
    }
  }
  
  @keyframes appear2 {
    0% {
      color: transparent;
      text-shadow: 3px 3px 5px transparent;
    }
    100% {
      color: #fcba03;
      text-shadow:  3px 3px 5px #2b2b2b;
    }
  }