#add-review-container {
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 1.5rem;
    min-height: 10rem;
    background: #dbdbdb;
}

.submit-flex {
    display: flex;
    justify-content: flex-end;
}

#submit-review-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    background-color: #4891FF;
    color: white;
    border-radius: 0.4rem;
    transition-duration: 0.4s;
}

#submit-review-button:hover {
    background-color: white;
    color: black;
}