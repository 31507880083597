.searchy {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color:rgb(230, 230, 230);
}

.search-layout {
    width: 80%;
    min-height: 50rem;
    background-color: #fcba03;
    box-shadow: 12px 0 30px -4px rgba(95, 95, 95, 0.7), -12px 0 30px -4px rgba(95, 95, 95, 0.7);
}

.no-results {
    padding: 2rem;
    margin: 2rem;
    border-radius: 2rem;
    font-size: 1.5rem;
    text-align: center;
}

@media (max-width:900px) {
    .search-layout {
        width: 100%;
    }
}