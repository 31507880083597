@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#title-with-image {
  background-size: cover;
  background-image: url('/chairs.jpg');
  height: 30rem;
}
  
#title-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, 60%);
}
  
.main-title-1 {
  color: #fcba03;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9rem;
  font-weight:bolder;
  text-shadow: 5px 5px 10px #2b2b2b;
  -webkit-animation: appear2 1s;
          animation: appear2 1s;
}

.main-title-2 {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9rem;
  font-weight:bolder;
  text-shadow: 5px 5px 10px #2b2b2b;
  -webkit-animation: appear 1s;
          animation: appear 1s;
}

.sub-title-text {
    color: white;
    width: 100%;
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
    transform: translate(0%, 300%);
    text-shadow: 3px 3px 5px #2b2b2b;
}

.desc-container-container {
  width: 100%;
  background-color: rgb(238, 238, 238);
}

.description-flex {
  min-height: 11rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top:2rem;
  margin-bottom:2rem;
}

.simple-flex {
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 3rem;
}

.description-1 {
  color: black;
  margin: 0;
  max-width: 30rem;
  margin-right: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 2rem;
}

.highlight {
  color: #fcba03;
}

.description-2 {
  color: black;
  margin: 0;
  max-width: 30rem;
  padding-top: 1rem;
  margin-right: 3rem;
  margin-left: 3rem;
  text-align: left;
}

.database-container {
  margin-right: 2rem;
}

.helper-container {
  margin: 2rem;
  padding: 2rem;
  background-color:white;
  box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.4);
  font-family: 'Open Sans', sans-serif;
  border-radius: 2rem;
  font-weight: bold;
}

.use-background {
    background-size: cover;
    background-image: url('/cu_campus.jpg');
    min-height: 20rem;
}

.use-container {
    background-color: rgba(252, 186, 3, 0.7); 
    height:100%;
    padding-top: 2rem;
}

.use-container p {
    color: black;
    margin: 0;
    text-align: center;
    padding-top: 1rem;
}

.use-grid {
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    height: 15rem;
    max-width: 1200px;
    margin: 0 auto;
}

.use-section {
    margin: 0 auto;
    display: block;
}

.contribute {
  color: white;
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.3rem;
  text-shadow: 3px 3px 6px #2b2b2b;
  margin-bottom:1rem;
}

.icon-credit {
  color: gray;
  font-size: 0.7rem;
}

@media (max-width:900px) {

  #title-with-image {
      height: 15rem;
  }

  #title-flex {
      transform: translate(0%, 150%);
  }    

  .about-container{
      display: block;
  }

  .overlap{
      display: none;
  }

  .sub-title-text {
      font-size: 0.75rem;
      transform: translate(0%, 650%);
  }

  .main-title-1 {
      font-size: 3rem;
  }

  .main-title-2 {
    font-size: 3rem;
  }

  .use-grid {
      display: block;
  }

  .use-background {
    min-height: 35rem;
  }

  .use-container {
    min-height: 35rem;
  }
    
  .icon-credit {
    display: none;
  }

  .database-container {
    display: none;
  }
}

@-webkit-keyframes appear {
    0% {
      color: transparent;
      text-shadow: 5px 5px 10px transparent;
    }
    100% {
      color: white;
      text-shadow: 5px 5px 10px #2b2b2b;
    }
  }

@keyframes appear {
    0% {
      color: transparent;
      text-shadow: 5px 5px 10px transparent;
    }
    100% {
      color: white;
      text-shadow: 5px 5px 10px #2b2b2b;
    }
  }
  
  @-webkit-keyframes appear2 {
    0% {
      color: transparent;
      text-shadow: 3px 3px 5px transparent;
    }
    100% {
      color: #fcba03;
      text-shadow:  3px 3px 5px #2b2b2b;
    }
  }
  
  @keyframes appear2 {
    0% {
      color: transparent;
      text-shadow: 3px 3px 5px transparent;
    }
    100% {
      color: #fcba03;
      text-shadow:  3px 3px 5px #2b2b2b;
    }
  }
.big-button {
    margin:0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: block;
    min-height: 3rem;
    min-width: 10rem;
    font-size: 1rem;

    border-radius: 2rem;
    border: none;
}

.big-button:hover {
    transition-duration: 0.4s;
    background-color: #2c2c2c;
    color: white;
  }
html { 
  background-color: #2c2c2c;
}

body {
  height: 100vh;
}

p {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

textarea {
  resize: none;
}

.center-text {
  align-content: center;
}

.top-nav {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #2c2c2c;
  background: linear-gradient(to bottom, #2c2c2c,#1b1b1b);

  z-index: 100;
  color: white;
  height: 4rem;

  display: grid;
  grid-template-columns: 1.0fr 1fr 2fr 0.5fr;
  justify-items: center;

  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
}

.hor-center {
  text-align: center;
  display: block;
}

.logo {
  align-self: center;
  height: 4rem;
  color: white;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  line-height: 3.8rem;
  font-size: 2rem;
  letter-spacing: 0.2rem;
  margin-left: 1rem;
}

.logo img:hover {
  cursor: pointer;
}

.school-dropdown-container {
  margin-top: 1.1rem;
}

.school-selector-dropdown {
  align-self: center;
  background-color: white;
  width: 80%;
  height: 55%;
  border:none;
  padding-left: 1rem;
  padding-right: 1rem;
  text-overflow: ellipsis;
}

.dropdown-content-school-container {
  background-color: white;
  color: black;
  width: 185px;
  display: none;
  position: absolute;
  outline:1px solid gray;
}

.dropdown-content-course-container {
  background-color: white;
  color: black;
  top: 3rem;
  display: none;
  position: absolute;
  outline:1px solid gray;
}

.dropdown-option {
  color: black;
  font-size: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left:0.5rem;
}

.dropdown-option:hover {
  background-color: rgb(197, 197, 197);
  cursor: pointer;
}

.course-search-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 45%;
  background-color: white;
  margin-top: 1.1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 2rem;
}

.course-search {
  background-color:transparent;
  align-self: center;
  width: 90%;
  height: 100%;
  border:none;
  padding-left: 1rem;
  padding-right: 1rem;
}

#search-icon-button{
  background-color: transparent;
  border: none;
  padding-right: 1rem;
}

.content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  align-content: center;

  display: grid;
  grid-template-columns: 3.0fr 1fr;
  grid-gap: 1rem;
}

.content-wrapper {
  margin-top:4rem;
}

.main {
  grid-column: 1;
}

.side {
  grid-column: 2;
}

.footer {
  height: 10rem;
  color:rgb(124, 124, 124);
  font-family: Arial, Helvetica, sans-serif;
  background-color: #2c2c2c;
}

.footer-text-1 {
  padding-top: 4rem;
}

.footer-text-2 {
  padding-top: 0.5rem;
  color: rgb(153, 153, 153);
}

.ad-container {
  height: 5rem;
}

@media (max-width:900px) {
  
  .main {
      grid-column: 1/3;
  }

  .side {
      display: none;
  }

  .logo {
    grid-column: 1;
  }

  .school-dropdown-container {
    display: none;
  }

  .school-selector-dropdown {
    display: none;
  }

  .course-search-container {
    grid-column: 2/5;
  }

  .course-search {
    grid-column: 2/5;
  }  

  .main-title {
    font-size: 3rem;
  }

  .main-title-sub {
    font-size: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.searchy {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color:rgb(230, 230, 230);
}

.search-layout {
    width: 80%;
    min-height: 50rem;
    background-color: #fcba03;
    box-shadow: 12px 0 30px -4px rgba(95, 95, 95, 0.7), -12px 0 30px -4px rgba(95, 95, 95, 0.7);
}

.no-results {
    padding: 2rem;
    margin: 2rem;
    border-radius: 2rem;
    font-size: 1.5rem;
    text-align: center;
}

@media (max-width:900px) {
    .search-layout {
        width: 100%;
    }
}
.search-result-container {
    background-color: rgb(255, 222, 152);
    min-height: 2rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    transition-duration: 0.3s;
}

.search-result-container:hover {
    background-color: rgb(231, 231, 231);
    cursor: pointer;
}

.school-name-container {
    font-size: 1.2rem;
    text-overflow: ellipsis;
}

.course-section-flex {
    display: flex;
    font-size: 2rem;
}

.course-id-container {
    font-weight: 600;
    padding-right: 1rem;
    text-overflow: ellipsis;
}

@media (max-width:900px) {
    .course-section-flex {
        display: block;
        font-size: 1.3rem;
    }
}
.underline {
  /* border: 0.05rem solid #8B939C; */
  border:none;
  height: 20px;
 	width: 100%;
	height: 50px;
	margin-top: 0;
	border-bottom: 1px solid #1f1209;
	box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 10px; 
  margin-bottom: 1rem;
}
.coursey {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color:rgb(230, 230, 230);
}

.top-container {
    padding: 2rem;
    padding-top: 6rem;
    z-index: 2;
    background-color: #fcba03;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.content-container {
    max-width: 60rem;
    margin: 0 auto;
}

.course-layout {
    z-index: 1;
    width: 80%;
    min-height: 50rem;
    background-color: #dbdbdb;
    box-shadow: 12px 0 30px -4px rgba(95, 95, 95, 0.7), -12px 0 30px -4px rgba(95, 95, 95, 0.7);
}

.top-flex{
    display: flex;
    justify-content: space-between;
}

.title-flex{
    border-bottom: 1px solid black;
    display: inline-flex;
}

.course-id{
    font-family: 'Roboto';
    font-size: 2.2rem;
    padding-right: 1.5rem;
    font-weight: 400;
}

.course-name {
    font-family: 'Roboto';
    font-size: 2.2rem;
    font-weight: 300;
}

.school-name {
    margin-top: 0.4rem;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: lighter;
}

.review-container {
    margin-top: 2rem;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
}

.review-text {
    margin-right: 0.5rem;
    line-height: 1.7rem;
}

.review-flex {
    display: inline-flex;
}

.review-container > br {
    display: none;
}

.fraction-container {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: rgb(61, 61, 61);
    border-radius: 0.3rem;
}

.review-actual-value {
    padding-bottom: 0.5rem;
    font-weight: bolder;
    font-size:1.2rem;
}

.review-max-value {
    padding-top: 0.5rem;
    color: white;
}

.roboto {
    font-family: 'Roboto';
    font-weight: 300;
}

#no-comment-span {
    text-align: center;
    width: 100%;
}

.button-flex {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}

.add-button {
    margin-right: 1.2rem;
    margin-left: 1.2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    background-color: #4891FF;
    color: white;
    border-radius: 0.4rem;
    transition-duration: 0.4s;
}

.add-button:hover {
    /* transition-duration: 0.4s; */
    background-color: white;
    color: black;
}

#no-comment{
    color: #616161;
    width: 100%;
}

@media (max-width:900px) {
    .course-layout {
        width: 100%;
    }

    .review-container {
        display: block;
    }

    .review-container > br{
        display: block;
    }

    .course-name {
        font-size: 1.5rem;
    }

    .course-id{
        font-size: 1.5rem;
        padding-right: 1.0rem;
    }

    .school-name {
        font-size: 1rem;
    }
}
.comment-container{
    background: white;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
}

.comment-title {
    display: inline-flex;
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.comment-content {
    
}

.bottom-row-button-flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    --gap: 0.4rem;
}

.bottom-row-button-flex > * {
    margin-left: var(--gap);
}

.button-no-background {
    background: none;
    border: none;
    transition-duration: 0.4s;
}
#modal{
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    min-width: 30rem;
    background-color: #dbdbdb;
    border-radius: 2rem;
    z-index: 1000;
    border-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    border-top: 2rem solid #fcba03;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
}

#modal-dark-backdrop {
  position: fixed;
  top: -10rem;
  width: 100vw; 
  height: 200vh;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
}

#modal-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

@media (max-width:900px) {
  #modal {
      min-width: 0rem;
      width: 85%;
  }
}
.required-star {
    color: red;
}
.selectable-dropdown{
    border: none;
    border-radius: 0.3rem;
    height: 1.5rem;
    padding-left:1rem; 
}
#add-review-container {
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 1.5rem;
    min-height: 10rem;
    background: #dbdbdb;
}

.submit-flex {
    display: flex;
    justify-content: flex-end;
}

#submit-review-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    background-color: #4891FF;
    color: white;
    border-radius: 0.4rem;
    transition-duration: 0.4s;
}

#submit-review-button:hover {
    background-color: white;
    color: black;
}
.input-container{
    margin-bottom: 1rem;
}

.input-label {
    font-weight: 600;
}

.input-description {
    font-style: italic;
    font-size: 0.8rem;
    max-width: 30rem;
    margin-bottom: 0.5rem;
}

.input-field {
    border: none;
    border-radius: 0.3rem;
    height: 1.5rem;
    padding-left:1rem;
    min-width: 15rem;
}

.validation-message {
    margin-top: 0.3rem;
    font-style: italic;
    color: red;
    font-size: 0.8rem;
}

.input-flex {
    display: inline-flex;
}

.required-star {
    color:red;
}
#add-comment-container {
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 1.5rem;
    min-height: 10rem;
    background: #dbdbdb;
}

.submit-flex {
    display: flex;
    justify-content: space-between;
}

#submit-comment-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    background-color: #4891FF;
    color: white;
    border-radius: 0.4rem;
    transition-duration: 0.4s;
}

#submit-comment-button:hover {
    background-color: white;
    color: black;
}

.comment-label {
    font-weight: 600;
}

.comment-input-container {
    margin-bottom: 1rem;
}

.comment-text-area{
    border: none;
    border-radius: 0.3rem;
    width: 70%;
    min-height: 10rem;
    padding: 1rem;
    margin-top: 0.5rem;
}
.add-course-layout {
    z-index: 1;
    width: 80%;
    min-height: 50rem;
    background-color: #dbdbdb;
    box-shadow: 12px 0 30px -4px rgba(95, 95, 95, 0.7), -12px 0 30px -4px rgba(95, 95, 95, 0.7);
}

.add-course-title {
    font-family: 'Roboto';
    font-size: 2.2rem;
    font-weight: 400;
}

.preview-course {
    background: white;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.school-selector-dropdown-add {
    margin-top: 0.4rem;
    background-color: white;
    min-width: 15rem;
    border:none;
    padding-left: 1rem;
    height: 1.5rem;
}

@media (max-width:900px) {
    .add-course-layout {
        width: 100%;
    }
}

