.search-result-container {
    background-color: rgb(255, 222, 152);
    min-height: 2rem;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    transition-duration: 0.3s;
}

.search-result-container:hover {
    background-color: rgb(231, 231, 231);
    cursor: pointer;
}

.school-name-container {
    font-size: 1.2rem;
    text-overflow: ellipsis;
}

.course-section-flex {
    display: flex;
    font-size: 2rem;
}

.course-id-container {
    font-weight: 600;
    padding-right: 1rem;
    text-overflow: ellipsis;
}

@media (max-width:900px) {
    .course-section-flex {
        display: block;
        font-size: 1.3rem;
    }
}