html { 
  background-color: #2c2c2c;
}

body {
  height: 100vh;
}

p {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

textarea {
  resize: none;
}

.center-text {
  align-content: center;
}

.top-nav {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #2c2c2c;
  background: linear-gradient(to bottom, #2c2c2c,#1b1b1b);

  z-index: 100;
  color: white;
  height: 4rem;

  display: grid;
  grid-template-columns: 1.0fr 1fr 2fr 0.5fr;
  justify-items: center;

  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
}

.hor-center {
  text-align: center;
  display: block;
}

.logo {
  align-self: center;
  height: 4rem;
  color: white;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  line-height: 3.8rem;
  font-size: 2rem;
  letter-spacing: 0.2rem;
  margin-left: 1rem;
}

.logo img:hover {
  cursor: pointer;
}

.school-dropdown-container {
  margin-top: 1.1rem;
}

.school-selector-dropdown {
  align-self: center;
  background-color: white;
  width: 80%;
  height: 55%;
  border:none;
  padding-left: 1rem;
  padding-right: 1rem;
  text-overflow: ellipsis;
}

.dropdown-content-school-container {
  background-color: white;
  color: black;
  width: 185px;
  display: none;
  position: absolute;
  outline:1px solid gray;
}

.dropdown-content-course-container {
  background-color: white;
  color: black;
  top: 3rem;
  display: none;
  position: absolute;
  outline:1px solid gray;
}

.dropdown-option {
  color: black;
  font-size: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left:0.5rem;
}

.dropdown-option:hover {
  background-color: rgb(197, 197, 197);
  cursor: pointer;
}

.course-search-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 45%;
  background-color: white;
  margin-top: 1.1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 2rem;
}

.course-search {
  background-color:transparent;
  align-self: center;
  width: 90%;
  height: 100%;
  border:none;
  padding-left: 1rem;
  padding-right: 1rem;
}

#search-icon-button{
  background-color: transparent;
  border: none;
  padding-right: 1rem;
}

.content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  align-content: center;

  display: grid;
  grid-template-columns: 3.0fr 1fr;
  grid-gap: 1rem;
}

.content-wrapper {
  margin-top:4rem;
}

.main {
  grid-column: 1;
}

.side {
  grid-column: 2;
}

.footer {
  height: 10rem;
  color:rgb(124, 124, 124);
  font-family: Arial, Helvetica, sans-serif;
  background-color: #2c2c2c;
}

.footer-text-1 {
  padding-top: 4rem;
}

.footer-text-2 {
  padding-top: 0.5rem;
  color: rgb(153, 153, 153);
}

.ad-container {
  height: 5rem;
}

@media (max-width:900px) {
  
  .main {
      grid-column: 1/3;
  }

  .side {
      display: none;
  }

  .logo {
    grid-column: 1;
  }

  .school-dropdown-container {
    display: none;
  }

  .school-selector-dropdown {
    display: none;
  }

  .course-search-container {
    grid-column: 2/5;
  }

  .course-search {
    grid-column: 2/5;
  }  

  .main-title {
    font-size: 3rem;
  }

  .main-title-sub {
    font-size: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}