#add-comment-container {
    margin-top: 1rem;
    padding: 2rem;
    border-radius: 1.5rem;
    min-height: 10rem;
    background: #dbdbdb;
}

.submit-flex {
    display: flex;
    justify-content: space-between;
}

#submit-comment-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    background-color: #4891FF;
    color: white;
    border-radius: 0.4rem;
    transition-duration: 0.4s;
}

#submit-comment-button:hover {
    background-color: white;
    color: black;
}

.comment-label {
    font-weight: 600;
}

.comment-input-container {
    margin-bottom: 1rem;
}

.comment-text-area{
    border: none;
    border-radius: 0.3rem;
    width: 70%;
    min-height: 10rem;
    padding: 1rem;
    margin-top: 0.5rem;
}