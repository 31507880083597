.big-button {
    margin:0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: block;
    min-height: 3rem;
    min-width: 10rem;
    font-size: 1rem;

    border-radius: 2rem;
    border: none;
}

.big-button:hover {
    transition-duration: 0.4s;
    background-color: #2c2c2c;
    color: white;
  }