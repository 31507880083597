.add-course-layout {
    z-index: 1;
    width: 80%;
    min-height: 50rem;
    background-color: #dbdbdb;
    box-shadow: 12px 0 30px -4px rgba(95, 95, 95, 0.7), -12px 0 30px -4px rgba(95, 95, 95, 0.7);
}

.add-course-title {
    font-family: 'Roboto';
    font-size: 2.2rem;
    font-weight: 400;
}

.preview-course {
    background: white;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.school-selector-dropdown-add {
    margin-top: 0.4rem;
    background-color: white;
    min-width: 15rem;
    border:none;
    padding-left: 1rem;
    height: 1.5rem;
}

@media (max-width:900px) {
    .add-course-layout {
        width: 100%;
    }
}