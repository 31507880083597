#modal{
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    min-width: 30rem;
    background-color: #dbdbdb;
    border-radius: 2rem;
    z-index: 1000;
    border-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    border-top: 2rem solid #fcba03;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
}

#modal-dark-backdrop {
  position: fixed;
  top: -10rem;
  width: 100vw; 
  height: 200vh;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
}

#modal-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

@media (max-width:900px) {
  #modal {
      min-width: 0rem;
      width: 85%;
  }
}