.input-container{
    margin-bottom: 1rem;
}

.input-label {
    font-weight: 600;
}

.input-description {
    font-style: italic;
    font-size: 0.8rem;
    max-width: 30rem;
    margin-bottom: 0.5rem;
}

.input-field {
    border: none;
    border-radius: 0.3rem;
    height: 1.5rem;
    padding-left:1rem;
    min-width: 15rem;
}

.validation-message {
    margin-top: 0.3rem;
    font-style: italic;
    color: red;
    font-size: 0.8rem;
}

.input-flex {
    display: inline-flex;
}

.required-star {
    color:red;
}