.comment-container{
    background: white;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
}

.comment-title {
    display: inline-flex;
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.comment-content {
    
}

.bottom-row-button-flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    --gap: 0.4rem;
}

.bottom-row-button-flex > * {
    margin-left: var(--gap);
}

.button-no-background {
    background: none;
    border: none;
    transition-duration: 0.4s;
}